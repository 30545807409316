export interface AdditionalData {
  [key: string]: string;
}

export interface Message {
  id: string;
  correlationId: string;
  conversationId: string;
  userId: string;
  sender: SenderType;
  event: EventType;
  text: string;
  additionalData: undefined | AdditionalData;
  timestamp: Date;
  feedback: undefined | -1 | 1;
  isError: boolean;
}

export enum EventType {
  UserInput = "UserInput",
  DispatcherNotification = "DispatcherNotification",
  FTFunctionRetrieved = "FTFunctionRetrieved",
  DocChatPartialRetrieved = "DocChatPartialRetrieved",
  DocChatRetrieved = "DocChatRetrieved",
  DebugLog = "DebugLog",
  LoadingNotification = "LoadingNotification",
  ErrorNotification = "ErrorNotification",
  Unknown = "Unknown",
}

export enum SenderType {
  User = "User",
  Dispatcher = "Dispatcher",
  FTFunction = "FTFunction",
  DocChat = "DocChat",
  Notifier = "Notifier",
  Unknown = "Unknown",
}
