import React, { useEffect } from "react";
import { useAuth } from "../../states/AuthContext";

const Login: React.FC = () => {
  const {signIn} = useAuth();
  
  useEffect(() => {
    signIn();
  }, []);

  return (
    <>
    </>
  );
};

export default Login;
