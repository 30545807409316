import {
  Caption1,
  Card,
  Divider,
  PositioningImperativeRef,
  Skeleton,
  SkeletonItem,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { useContext, useEffect, useMemo, useRef } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from "remark-supersub";
import { Message, SenderType } from "../../../../models/Message";
import { ChatFeatureContextHandler } from "../../../../states/ChatContext";
import "./ChatMessage.css";
import DocChat, { DocChatMessage } from "./DocChat";
import FTButton, { FtFunctionMessage } from "./FTButton";
import { parseMessage } from "./MessageParser";

const useStyles = makeStyles({
  copilotMessage: {
    minWidth: "300px",
    maxWidth: "600px",
  },
  errorMessage: {
    minWidth: "300px",
    maxWidth: "600px",
    color: "red",
  },
  userMessage: {
    minWidth: "150px",
    maxWidth: "600px",
    backgroundColor: "#EDF5FD",
  },
  /* Agent message styles */
  // Dispatcher
  dispatcherMessage: {
    minWidth: "300px",
    maxWidth: "600px",
    backgroundColor: "#FFF",
  },
  // DocChat
  docChatMessage: {
    minWidth: "300px",
    maxWidth: "600px",
    backgroundColor: "#FFF",
  },
  // Notification
  notificationMessage: {
    minWidth: "300px",
    maxWidth: "600px",
    backgroundColor: "#FFF3CD",
  },
  // Debug
  debugMessage: {
    minWidth: "300px",
    maxWidth: "600px",
    backgroundColor: "hotpink",
  },
  // FTFunction
  FTFunctionMessage: {
    minWidth: "300px",
    maxWidth: "600px",
    backgroundColor: "#FFF",
  },
  avatar: {
    ...shorthands.margin("0px", "5px"),
  },
  skeletonItem: {
    ...shorthands.margin("5px", "0px"),
  },
  feedbackContainer: {
    display: "flex",
    ...shorthands.padding("4px"),
  },
});

interface ChatMessageProps {
  message?: Message;
  isLoading?: boolean;
  isTakingTooLong?: boolean;
  waitingMessage?: string;
  loadingMessage?: string;
}

export function ChatMessage({
  message,
  isLoading,
  isTakingTooLong,
  loadingMessage,
  waitingMessage,
}: ChatMessageProps) {
  const feedbackRef = useRef<HTMLDivElement>(null);
  const positioningRef = useRef<PositioningImperativeRef>(null);
  const styles = useStyles();
  useContext(ChatFeatureContextHandler);

  const parsedMessage: Message | undefined = useMemo(
    () => parseMessage(message),
    [message]
  );

  useEffect(() => {
    if (feedbackRef.current) {
      positioningRef.current?.setTarget(feedbackRef.current);
    }
  }, [feedbackRef, positioningRef]);

  const getMessageStyle = (senderType: string) => {
    switch (senderType) {
      case "Notification":
        return styles.notificationMessage;
      case "DebugLog":
        return styles.debugMessage;
      case "FTFunction":
      case "FTFunctionNotification":
        return styles.FTFunctionMessage;
      case "DocChat":
        return styles.docChatMessage;
      default:
        return styles.copilotMessage;
    }
  };

  const isError = message?.isError === true;

  switch (true) {
    case isLoading:
      return (
        <div className="message-container">
          <Card className={styles.copilotMessage}>
            <Skeleton>
              {isTakingTooLong ? (
                <ReactMarkdown remarkPlugins={[remarkGfm, supersub]}>
                  {waitingMessage}
                </ReactMarkdown>
              ) : (
                <>{loadingMessage}</>
              )}
              <SkeletonItem className={styles.skeletonItem} size={16} />
              <SkeletonItem className={styles.skeletonItem} size={8} />
              <SkeletonItem className={styles.skeletonItem} size={16} />
              <SkeletonItem className={styles.skeletonItem} size={12} />
              <Divider>
                <Caption1 className="message-disclaimer">
                  Generating answer
                </Caption1>
              </Divider>
            </Skeleton>
          </Card>
        </div>
      );
    case message && message.sender !== SenderType.User && !isError:
      return (
        <div className="message-container">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card className={getMessageStyle(message.sender)}>
              <div className="feedback-container-target">
                <div ref={feedbackRef}></div>
              </div>
              {message.sender === SenderType.FTFunction && parsedMessage ? (
                <FTButton parsedMessage={parsedMessage as FtFunctionMessage} />
              ) : message.sender === SenderType.DocChat && parsedMessage ? (
                <DocChat parsedMessage={parsedMessage as DocChatMessage} />
              ) : (
                <ReactMarkdown remarkPlugins={[remarkGfm, supersub]}>
                  {parsedMessage?.text}
                </ReactMarkdown>
              )}
            </Card>
          </div>
        </div>
      );
    case message && message.sender !== SenderType.User && isError:
      return (
        <div className="message-container">
          <Card className={styles.errorMessage}>
            <ReactMarkdown remarkPlugins={[remarkGfm, supersub]}>
              {parsedMessage?.text}
            </ReactMarkdown>
          </Card>
        </div>
      );
    case message && message.sender === SenderType.User:
      return (
        <div className="message-container">
          <Card className={styles.userMessage}>{message.text}</Card>
        </div>
      );
    default:
      return <></>;
  }
}
