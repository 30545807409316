import { Tab, TabList } from "@fluentui/react-components";
import { Settings } from "luxon";
import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import chatIcon from "./assets/copilot_icon_text_bubble_black.svg";
import { NavBar } from "./components/NavBar/NavBar";
import { ChatPage } from "./pages/chat/ChatPage";
import Login from "./pages/login/Login";
import { useAuth } from "./states/AuthContext";
import Signin from "./pages/login/Signin";
import { getModeType, ModeType, persistModeType } from "./utils/localStorageUtils";
import LoginSmfAuth from "./pages/loginauth/LoginSmfAuth";

const useSmfAuth = import.meta.env.VITE_USE_SMF_AUTH === "true";

export default function App() {
  Settings.defaultLocale = "en-UK";

  createBrowserRouter([
    {
      path: "/",
      element: <ChatPage />,
    },
    {
      path: "/chat",
      element: <ChatPage />,
    },
  ]);

  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  // State for managing the selected tab
  const [selectedTab, setSelectedTab] = useState("chat");

  useEffect(() => {
    // Preselect the tab based on the current URL
    if (location.pathname === "/chat") {
      setSelectedTab("chat");
    }
  }, [location.pathname]);

   const isMastercam = new URLSearchParams(window.location.search).has(
      "mastercam"
    );

    if (isMastercam)
    {
      persistModeType(ModeType.MastercamMode);
    }
    else
    {
      if(getModeType() == ModeType.MastercamMode)
      {
        const {setMastercam} = useAuth();
        setMastercam(true);
        console.log("set Mastercam mode");
      }
    }

  const handleTabClick = (tabValue: string) => {
    setSelectedTab(tabValue); // Update the selected tab state
    navigate(tabValue); // Navigate to the page
  };

  return (
    <div className="body">
      <NavBar />
      {isAuthenticated && (
        <div className="app-header">
          <TabList selectedValue={selectedTab}>
            <Tab value="chat" onClick={() => handleTabClick("chat")}>
              <img src={chatIcon} alt="Chat Icon" className="tab-icon" />
              <span className="tab-text">Chat</span>
            </Tab>
          </TabList>
        </div>
      )}

      <Routes>
        <Route path="/" element={isAuthenticated ? <ChatPage /> : useSmfAuth ? <LoginSmfAuth /> : <Login />} />
        <Route path="/authentication/callback" element={<Signin />} ></Route>
        <Route path="/authentication/signout" element={useSmfAuth ? <LoginSmfAuth /> : <Login />} ></Route>
        <Route
          path="/chat"
          element={isAuthenticated ? <ChatPage /> : <Navigate to="/" />}
        />
      </Routes>
    </div>
  );
}
