import { UserManager, WebStorageStateStore } from 'oidc-client';

const oidcConfig = {
  authority: "https://users.mastercam.com/identity",
  client_id: "mastercam_copilot",
  scope: "openid email offline_access",
  redirect_uri: `${window.location.origin}/authentication/callback`,
  post_logout_redirect_uri: `${window.location.origin}/authentication/signout`,
  response_type: "code",
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  metadata: {
    "issuer": "https://users.mastercam.com/identity",
    "jwks_uri": "https://users.mastercam.com/identity/.well-known/openid-configuration/jwks",
    "authorization_endpoint": "https://users.mastercam.com/identity/connect/authorize",
    "token_endpoint": "https://users.mastercam.com/identity/connect/token",
    "userinfo_endpoint": "https://users.mastercam.com/identity/connect/userinfo",
    "end_session_endpoint": "https://users.mastercam.com/identity/connect/endsession",
    "check_session_iframe": "https://users.mastercam.com/identity/connect/checksession",
    "revocation_endpoint": "https://users.mastercam.com/identity/connect/revocation",
    "introspection_endpoint": "https://users.mastercam.com/identity/connect/introspect",
    "device_authorization_endpoint": "https://users.mastercam.com/identity/connect/deviceauthorization",
    "backchannel_authentication_endpoint": "https://users.mastercam.com/identity/connect/ciba",
    "pushed_authorization_request_endpoint": "https://users.mastercam.com/identity/connect/par"
  }
};

export const userManager = new UserManager(oidcConfig);
