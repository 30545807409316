import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  clearAll,
  getToken,
  getUsername,
  persistToken,
  persistUsername,
} from "../utils/localStorageUtils";

import { userManager } from "./Authentication";
import { Navigate } from "react-router-dom";
const useSmfAuth = import.meta.env.VITE_USE_SMF_AUTH === "true";

interface AuthContextType {
  isAuthenticated: boolean;
  isMastercam: boolean;
  token: string | null;
  username: string | null;
  signIn: () => void;
  signOut: () => void;
  login: (username: string, token: string, isMastercam: boolean) => void;
  logout: () => void;
  setMastercam: (isMastercam: boolean) => void;
  signinCallBack: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isMastercam, setIsMastercam] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const [username, setUsername] = useState<string | null>(null);

  // Load authentication state from local storage on initial render
  useEffect(() => {
    const storedToken = getToken();
    const storedUsername = getUsername();

    if (storedToken && storedUsername) {
      setIsAuthenticated(true);
      setToken(storedToken);
      setUsername(storedUsername);
    }


    const renewToken = () => {
      userManager.signinSilent()
        .then(user => {
          setToken(user.access_token);
          setUsername(user.profile.email || "unknown");
        })
        .catch(error => {
          console.error("Token renewal error", error);
          signOut();
        });
    };

    userManager.events.addAccessTokenExpiring(() => {
      console.log("Access token is about to expire");
      renewToken();
    });

    userManager.events.addAccessTokenExpired(() => {
      console.log("Access token has expired");
      signOut();
    });

    return () => {
      userManager.events.removeAccessTokenExpiring(renewToken);
      userManager.events.removeAccessTokenExpired(signOut);
    };
  }, []);

  const setMastercam = (isMastercam: boolean) => {
    setIsMastercam(isMastercam);
  }

  const signIn = () => {
    userManager.signinRedirect();
  }

  const signOut = () => {
    if (useSmfAuth) {
      logout();
      return;
    }
    setIsAuthenticated(false);
    setToken(null);
    setUsername(null);
    clearAll();

    userManager.signoutRedirect();
  };

  const signinCallBack = () => {
    userManager.signinRedirectCallback()
      .then(user => {
        setIsAuthenticated(true);
        console.log("set auth");
        const userName = user.profile.email || "unknown";
        persistUsername(userName);
        persistToken(user.access_token);
        setToken(user.access_token);
        setUsername(userName);
        window.location.href = "/chat";
      })
      .catch((error) => console.error("error", error));
  };

  const login = (username: string, token: string, isMastercam: boolean) => {
    persistUsername(username);
    persistToken(token);

    setIsAuthenticated(true);
    setToken(token);
    setUsername(username);
    setIsMastercam(isMastercam);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setToken(null);
    setUsername(null);
    clearAll();
    <Navigate to="/" />;
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, isMastercam, token, username, signIn, signOut, setMastercam, signinCallBack, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
