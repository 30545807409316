import {
  Button,
  Caption1,
  Divider,
  InputOnChangeData,
  Textarea,
  makeStyles,
} from "@fluentui/react-components";
import {
  AddSquareRegular,
  SendRegular,
  MicRegular,
  Mic28Filled,
  RecordFilled,
} from "@fluentui/react-icons";
import React, { useContext, useEffect, useState } from "react";
import { ChatFeatureContextHandler } from "../../../../states/ChatContext";
import "./ChatInputBox.css";
import { ChatSettingsBar } from "../ChatSettingsBar/ChatSettingsBar";

declare global {
  interface Window {
    SpeechRecognition: any;
    webkitSpeechRecognition: any;
  }
}

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";

const useStyles = makeStyles({
  button: {
    marginRight: "5px",
  },
});

export function ChatInputBox() {
  const styles = useStyles();
  const [isListening, setIsListening] = useState(false);
  const contextHandler = useContext(ChatFeatureContextHandler);
  const [message, setMessage] = useState("");
  const [showVoiceRecordingMessage, setShowVoiceRecordingMessage] =
    useState(false);

  useEffect(() => {
    handleListen();
  }, [isListening]);

  function onMessageChanged(
    _ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    data?: InputOnChangeData
  ) {
    setIsListening(false);
    setMessage(data?.value || "");
  }

  function requestMicrophonePermission() {
    try {
      navigator.mediaDevices.getUserMedia({ audio: true });
      // Do something with the audio stream
      console.log("Microphone permission granted");
    } catch (err) {
      console.error("Microphone permission denied", err);
    }
  }

  function onEnterPress(event: React.KeyboardEvent<Element>) {
    if (event.key === "Enter" && !event.shiftKey) {
      onSendMessageClicked();
      event.preventDefault();
    }
  }

  function onSendMessageClicked() {
    setIsListening(false);
    if (message) {
      contextHandler.onSendMessage(message);
      setMessage("");
    }
  }

  function handleListen() {
    if (isListening) {
      requestMicrophonePermission();
      setShowVoiceRecordingMessage(true);
      mic.start();
      mic.onend = () => {
        console.log("continue..");
        mic.start();
      };
    } else {
      setShowVoiceRecordingMessage(false);
      mic.stop();
      mic.onend = () => {
        console.log("Stopped Mic on Click");
      };
    }
    mic.onstart = () => {
      console.log("Mics on");
    };

    mic.onresult = (event: any) => {
      let transcript = Array.from(event.results)
        .map((result: any) => result[0].transcript)
        .join("");
      if (isListening && transcript) {
        const regex = /mc go|mcgo|mco/i;
        console.log("message received: " + transcript);
        if (regex.test(transcript)) {
          const newTranscript = transcript.replace(regex, "").trim();
          setMessage(newTranscript);
          console.log("automatic send of " + newTranscript);
          onSendMessageClicked();
        } else {
          setMessage(transcript);
        }
      }

      mic.onerror = (event: any) => {
        console.log(event.error);
      };
    };
  }

  return (
    <>
      <div className="recording-info">
        {showVoiceRecordingMessage && (
          <>
            <RecordFilled color="red" />
            <span>Voice recording...</span>
          </>
        )}
      </div>

      <Textarea
        value={message}
        size="large"
        className="input-message"
        onChange={onMessageChanged}
        onKeyDown={onEnterPress}
      />

      <Divider style={{ marginTop: "5px" }}>
        <Caption1 className="message-disclaimer">
          This chat will produce AI-generated content. Check important info
        </Caption1>
      </Divider>
      <div className="input-toolbar">
        <div>
          <Button
            icon={<AddSquareRegular />}
            size="large"
            className={styles.button}
            onClick={contextHandler.onRestartConversation}
          >
            New topic
          </Button>
        </div>
        <ChatSettingsBar />
        <div>
          <Button
            size="large"
            icon={isListening ? <Mic28Filled /> : <MicRegular />}
            className={styles.button}
            onClick={() => setIsListening((prevState) => !prevState)}
          ></Button>
          <Button
            icon={<SendRegular />}
            appearance="primary"
            size="large"
            onClick={onSendMessageClicked}
            disabled={!message}
          ></Button>
        </div>
      </div>
    </>
  );
}
